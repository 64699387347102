<template>
  <svg viewBox="0 0 24 24">
    <path
      d="M18 11V8L22 12L18 16V13H13V18H16L12 22L8 18H11V13H6V16L2 12L6 8V11H11V6H8L12 2L16 6H13V11H18Z"
    />
  </svg>
</template>

<script>
export default {
  name: "MoveIcon",
};
</script>
